import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants/index'
import axiosInstance, { microServices } from 'network/apis'

export const createAffiliateService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/affiliates/create-affiliate', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit
  })
}

export const getAffiliateDetailService = () => {
  return axiosInstance(METHOD_TYPES.get, '/affiliates/affiliate-details', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table
  })
}

export const getAllAffiliateService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/affiliates/all-affiliates', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getAllAffiliateTransactionsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/affiliates/all-transactions', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getAllCommissionsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/affiliates/all-commision', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const deleteAffiliateService = (affiliateId) => {
  return axiosInstance(METHOD_TYPES.delete, `/affiliates/${affiliateId}/delete-affiliate`, {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table
  })
}

export const getAllAffiliatePlayerService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/affiliates/affiliate-users', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getIndividualAffiliatePlayerStatsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/affiliates/all-affiliate-details', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}
/**
 * Service to update affiliate user status
 */
export const updateAffiliatePlayerStatusService = (data) => {
  return axiosInstance(METHOD_TYPES.put, '/affiliates/update-affiliate-userStatus', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    successMessage: 'Affiliate Player status updated successfully'
  })
}

export const getAffiliateStatsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/affiliates/affiliate-stats', {}, {
    server: microServices.USER_URL,
    loader: null,
    params: data
  })
}

export const getAllCommissionSettlementService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/affiliates/all-settlement', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const createAffiliateSettingsService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/affiliates/create-commision-setting', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: 'Affiliate Commission settings details created successfully'
  })
}

export const settleAffiliateCommissionService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/affiliates/commision-settlement', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: 'Affiliate Commission settled successfully'
  })
}

export const updateAffiliateCommissionService = (data) => {
  return axiosInstance(METHOD_TYPES.put, '/affiliates/update-commision-setting', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: 'Affiliate Commission settings details updated successfully'
  })
}
