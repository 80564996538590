import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants/index'
import axiosInstance, { microServices } from 'network/apis'

/**
 * Service to update promotion details
 */
export const updatePromotionDetailsService = (promotionData) => {
  return axiosInstance(METHOD_TYPES.put, '/admins/promotions', promotionData, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    headers: { 'Content-Type': 'multipart/form-data', Accept: '*/*' },
    successMessage: 'Promotions details updated successfully'
  })
}

/**
 * Service to update promotion status
 */
export const updatePromotionStatusService = (promotionUserData) => {
  return axiosInstance(METHOD_TYPES.put, '/admins/update-promotions-status', promotionUserData, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    successMessage: 'Promotion status updated successfully'
  })
}

/**
 * Service to create promotion
 * @param {object} data - object contains all required properties to create new promotion
 */
export const createPromotionService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/admins/promotions', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    headers: { 'Content-Type': 'multipart/form-data', Accept: '*/*' },
    successMessage: 'Promotion created successfully'
  })
}

/**
 * Service to fetch all promotion
 */
export const getAllPromotionsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/admins/promotions', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}
