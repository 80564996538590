import { combineReducers } from '@reduxjs/toolkit'
import authSlice from './authSlice'
import hamSlice from './hamSlice'
import toasterSlice from './toasterSlice'
import loaderSlice from './loaderSlice'
import playerSlice from './playerSlice'
import transactionsSlice from './transactionsSlice'
import gameReportsSlice from './gameReportsSlice'
import adminSlice from './adminSlice'
import sponsorSlice from './sponsorSlice'
import affiliateSlice from './affiliateSlice'
import bannerSlice from './bannerSlice'
import assetsSlice from './assetsSlice'
import cmsSlice from './cmsSlice'
import crmSlice from './crmSlice'
import gameSettingSlice from './gameSettingSlice'
import gameThemeSlice from './gameThemeSlice'
import dashboardSlice from './dashboardSlice'
import bonusSlice from './bonusSlice'
import upliftingWordsSlice from './upliftingWords'
import themeSlice from './themeSlice'
import adminLoginLogSlice from './systemManagement/loginLog'
import siteConfigsSlice from './siteConfigSlice'
import promotionSlice from './promotionSlice'
import chatSlice from './chat.slice'
import announcementSlice from './announcementSlice'
import wordsSlice from './wordsSlice'
import reportedUserSlice from './reportedUserSlice'
import theme from './theme.Slice'
import referralsSlice from './referralsSlice'
import rankingLevelSlice from './rankingLevelsSlice'
import cryptoInstrumentSlice from './cryptoInstrumentSlice'

export const rootReducer = combineReducers({
  auth: authSlice,
  ham: hamSlice,
  toaster: toasterSlice,
  loader: loaderSlice,
  dashboard: dashboardSlice,
  systemMgmt: combineReducers({
    adminLoginLogs: adminLoginLogSlice
  }),
  players: playerSlice,
  transactions: transactionsSlice,
  gameReports: gameReportsSlice,
  admins: adminSlice,
  sponsors: sponsorSlice,
  banners: bannerSlice,
  gameSetting: gameSettingSlice,
  gameTheme: gameThemeSlice,
  cms: cmsSlice,
  crm: crmSlice,
  bonus: bonusSlice,
  themes: themeSlice,
  theme: theme,
  assets: assetsSlice,
  upliftingWords: upliftingWordsSlice,
  affiliates: affiliateSlice,
  siteConfiguration: siteConfigsSlice,
  promotions: promotionSlice,
  chat: chatSlice,
  announcement: announcementSlice,
  words: wordsSlice,
  reportedUser: reportedUserSlice,
  referrals: referralsSlice,
  rankingLevels: rankingLevelSlice,
  cryptoInstrument: cryptoInstrumentSlice
})
