// import React from 'react'
// import ReactDOM from 'react-dom'
// import 'assets/styles/index.css'
// import App from 'App'
// import reportWebVitals from 'reportWebVitals'
// import 'assets/translation'
// import { appRoot } from 'assets/dom/domNodes'
// import { Provider, useSelector } from 'react-redux'
// import { theme } from 'theme/theme'
// import getTheme from 'theme/theme'
// import { ThemeProvider } from '@mui/material'
// import { store } from 'redux-thunk/store'
// import { AppStyleWrap } from 'App.Styles'

// const Root = () => {
//   const themeMode = useSelector(state => state.theme.mode)
//   const isDarkMode = themeMode === 'dark'

//   const theme = getTheme(isDarkMode)
// }

// ReactDOM.render(
//   <React.StrictMode>
//     <ThemeProvider theme={theme}>
//       <Provider store={store}>
//         <AppStyleWrap>
//           <App />
//         </AppStyleWrap>
//       </Provider>
//     </ThemeProvider>
//   </React.StrictMode>,
//   appRoot
// )

// export default store

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()

import React from 'react'
import { useSelector, Provider } from 'react-redux'
import ReactDOM from 'react-dom'
import 'assets/styles/index.css'
import App from 'App'
import { appRoot } from 'assets/dom/domNodes'
import reportWebVitals from 'reportWebVitals'
import 'assets/translation'
import { store } from 'redux-thunk/store'
import getTheme from 'theme/theme'
import { ThemeProvider } from '@mui/material'
import { AppStyleWrap } from 'App.Styles'

const Root = () => {
  const themeMode = useSelector(state => state.theme.mode)
  const isDarkMode = themeMode === 'dark'

  const theme = getTheme(isDarkMode)

  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <AppStyleWrap>
            <App />
          </AppStyleWrap>
        </Provider>
      </ThemeProvider>
    </React.StrictMode>
  )
}

ReactDOM.render(
  <Provider store={store}>
    <Root />
  </Provider>,
  appRoot
)

export default store

reportWebVitals()
