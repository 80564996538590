import { createSlice } from '@reduxjs/toolkit'
import { fetchAllGameSetting, fetchAllGames, fetchSportsbookGames, fetchGamesAggregators, fetchGamesProvider, fetchSportsbettingTransactions, fetchCasinoTransactions, fetchAllSmartSoftCasinoGames, fetchCasinoCatagories, fetchCryptoBetTransactions, fetchRollerCoasterTransactions } from 'redux-thunk/thunk/gameSetting'

const initialState = {
  allGameSetting: null,
  gameSettingDetail: null,
  allGames: null,
  sportsbookGames: null,
  gamesProviders: null,
  gamesAggregators: null,
  sportsbettingTransactions: null,
  casinoTransactions: null,
  casinoGames: null,
  casinoCategories: null,
  cryptoBetTransactions: null,
  rollerCoasterTransactions: null
}

const gameSettingSlice = createSlice({
  name: 'gameSettingSlice',
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllGameSetting.fulfilled, (state, action) => {
        return {
          ...state,
          allGameSetting: action.payload.gameSettings
        }
      })
      .addCase(fetchAllGames.fulfilled, (state, action) => {
        return {
          ...state,
          allGames: action.payload
        }
      })
      .addCase(fetchSportsbookGames.fulfilled, (state, action) => {
        return {
          ...state,
          sportsbookGames: action.payload
        }
      })
      .addCase(fetchGamesProvider.fulfilled, (state, action) => {
        return {
          ...state,
          gamesProviders: action.payload
        }
      })
      .addCase(fetchGamesAggregators.fulfilled, (state, action) => {
        return {
          ...state,
          gamesAggregators: action.payload
        }
      })
      .addCase(fetchSportsbettingTransactions.fulfilled, (state, action) => {
        return {
          ...state,
          sportsbettingTransactions: action.payload
        }
      })
      .addCase(fetchCasinoTransactions.fulfilled, (state, action) => {
        return {
          ...state,
          casinoTransactions: action.payload
        }
      })
      .addCase(fetchAllSmartSoftCasinoGames.fulfilled, (state, action) => {
        return {
          ...state,
          casinoGames: action.payload
        }
      })
      .addCase(fetchCasinoCatagories.fulfilled, (state, action) => {
        return {
          ...state,
          casinoCategories: action.payload
        }
      })
      .addCase(fetchCryptoBetTransactions.fulfilled, (state, action) => {
        return {
          ...state,
          cryptoBetTransactions: action.payload
        }
      })
      .addCase(fetchRollerCoasterTransactions.fulfilled, (state, action) => {
        return {
          ...state,
          rollerCoasterTransactions: action.payload
        }
      })
  }
})

export default gameSettingSlice.reducer
