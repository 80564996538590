import { v4 as uuidv4 } from 'uuid'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'
import SportsSoccerOutlinedIcon from '@mui/icons-material/SportsSoccerOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined'
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined'
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined'
// import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined'
import CampaignIcon from '@mui/icons-material/Campaign'
import StreamIcon from '@mui/icons-material/StreamOutlined'
import DomainIcon from '@mui/icons-material/Domain'
// import ViewCarouselIcon from '@mui/icons-material/ViewCarousel'
import LayersIcon from '@mui/icons-material/Layers'
import EmailIcon from '@mui/icons-material/Email'
import CollectionsIcon from '@mui/icons-material/Collections'
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard'
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined'
import StorageIcon from '@mui/icons-material/Storage'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import RewardIcon from '@mui/icons-material/Star'
import AnnouncementSharpIcon from '@mui/icons-material/AnnouncementSharp'
import FlagIcon from '@mui/icons-material/Flag'
import ShareIcon from '@mui/icons-material/Share'
// import CellTowerIcon from '@mui/icons-material/CellTower'
// import PaletteIcon from '@mui/icons-material/Palette'
import { ROUTE_PATHS } from 'utils/constants/index'
import LocalAtmIcon from '@mui/icons-material/LocalAtm'
import ReportIcon from '@mui/icons-material/Report'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'

export const sidebarOptions = [
  {
    key: uuidv4(),
    label: 'dashboard',
    path: ROUTE_PATHS.dashboard,
    icon: DashboardOutlinedIcon,
    subMenus: []
  },
  {
    key: uuidv4(),
    label: 'systemManagement',
    icon: SettingsOutlinedIcon,
    subMenus: [
      {
        key: uuidv4(),
        label: 'admins',
        path: ROUTE_PATHS.admins,
        icon: DomainIcon,
        subMenus: []
      },
      {
        key: uuidv4(),
        label: 'roleManagement',
        path: ROUTE_PATHS.roleManagement,
        icon: CircleOutlinedIcon,
        subMenus: []
      },
      // {
      //   key: uuidv4(),
      //   label: 'ipManangement',
      //   path: ROUTE_PATHS.ipManagement,
      //   icon: CircleOutlinedIcon,
      //   subMenus: []
      // },
      // {
      //   key: uuidv4(),
      //   label: 'operationLog',
      //   path: ROUTE_PATHS.operationLog,
      //   icon: CircleOutlinedIcon,
      //   subMenus: []
      // },
      {
        key: uuidv4(),
        label: 'loginLog',
        path: ROUTE_PATHS.systemManagementLoginLogs,
        icon: CircleOutlinedIcon,
        subMenus: []
      },
      {
        key: uuidv4(),
        label: 'siteConfiguration',
        path: ROUTE_PATHS.siteConfiguration,
        icon: CircleOutlinedIcon,
        subMenus: []
      }
      // {
      //   key: uuidv4(),
      //   label: 'gameSettings',
      //   path: ROUTE_PATHS.gameSettings,
      // icon: CircleOutlinedIcon
      // },
      // {
      //   key: uuidv4(),
      //   label: 'gameThemeSettings',
      //   path: ROUTE_PATHS.gameThemeSettings,
      //   icon: CircleOutlinedIcon
      // },
      // {
      //   key: uuidv4(),
      //   label: 'crashUpliftingWords',
      //   path: ROUTE_PATHS.upliftingWords,
      //   icon: CircleOutlinedIcon
      // }
    ]
  },

  {
    key: uuidv4(),
    label: 'players',
    icon: GroupsOutlinedIcon,
    subMenus: [
      {
        key: uuidv4(),
        label: 'playerList',
        path: ROUTE_PATHS.users,
        icon: CircleOutlinedIcon
      },
      {
        key: uuidv4(),
        label: 'onlinePlayer',
        path: ROUTE_PATHS.onlinePlayer,
        icon: CircleOutlinedIcon
      },
      // {
      //   key: uuidv4(),
      //   label: 'playerBalance',
      //   path: ROUTE_PATHS.playerBalance,
      //   icon: CircleOutlinedIcon
      // },
      {
        key: uuidv4(),
        label: 'loginLog',
        path: ROUTE_PATHS.loginLog,
        icon: CircleOutlinedIcon
      }
    ]
  },
  {
    key: uuidv4(),
    label: 'gameManagement',
    icon: SportsSoccerOutlinedIcon,
    subMenus: [
      // {
      //   key: uuidv4(),
      //   label: 'aggregators',
      //   path: ROUTE_PATHS.gamesAggregators,
      //   icon: CircleOutlinedIcon
      // },
      // {
      //   key: uuidv4(),
      //   label: 'providers',
      //   path: ROUTE_PATHS.gamesProviders,
      //   icon: CircleOutlinedIcon
      // },
      // {
      //   key: uuidv4(),
      //   label: 'games',
      //   path: ROUTE_PATHS.gamesSportsbook,
      //   icon: CircleOutlinedIcon
      // },
      // {
      //   key: uuidv4(),
      //   label: 'categories',
      //   path: ROUTE_PATHS.gamesCategories,
      //   icon: CircleOutlinedIcon
      // },
      {
        key: uuidv4(),
        label: 'customGames',
        path: ROUTE_PATHS.games,
        icon: CircleOutlinedIcon
      },
      {
        key: uuidv4(),
        label: 'customGameSettings',
        path: ROUTE_PATHS.gameSettings,
        icon: CircleOutlinedIcon
      }
    ]
  },
  {
    key: uuidv4(),
    label: 'transactions',
    icon: AccountBalanceWalletIcon,
    subMenus: [
      // {
      //   key: uuidv4(),
      //   label: 'customGamesTransactions',
      //   path: ROUTE_PATHS.transactions,
      //   icon: CircleOutlinedIcon
      // },
      // {
      //   key: uuidv4(),
      //   label: 'casinoTransactions',
      //   path: ROUTE_PATHS.casinoTransactions,
      //   icon: CircleOutlinedIcon
      // },
      // {
      //   key: uuidv4(),
      //   label: 'sportsbettingTransactions',
      //   path: ROUTE_PATHS.sportsbettingTransactions,
      //   icon: CircleOutlinedIcon
      // },
      {
        key: uuidv4(),
        label: 'cryptoBetTransactions',
        path: ROUTE_PATHS.cryptoBetTransactions,
        icon: CircleOutlinedIcon
      },
      {
        key: uuidv4(),
        label: 'rollerCoasterTransactions',
        path: ROUTE_PATHS.rollerCoasterTransactions,
        icon: CircleOutlinedIcon
      }
    ]
  },
  {
    key: uuidv4(),
    label: 'financialReport',
    icon: ReportIcon,
    subMenus: [
      // {
      //   key: uuidv4(),
      //   label: 'casinoP&LReport',
      //   path: ROUTE_PATHS.casinoProfitAndLossTransactions,
      //   icon: CircleOutlinedIcon
      // },
      {
        key: uuidv4(),
        label: 'cryptoFutureReport',
        path: ROUTE_PATHS.cryptoFutureReport,
        icon: CircleOutlinedIcon
      },
      {
        key: uuidv4(),
        label: 'rollerCoasterReport',
        path: ROUTE_PATHS.rollerCoasterReport,
        icon: CircleOutlinedIcon
      }
    ]
  },
  {
    key: uuidv4(),
    label: 'databaseExplorer',
    path: ROUTE_PATHS.databaseExplorer,
    icon: StorageIcon,
    subMenus: []
  },
  // {
  //   key: uuidv4(),
  //   label: 'influencers',
  //   path: ROUTE_PATHS.influencers,
  //   icon: CellTowerIcon,
  //   subMenus: []
  // },
  // {
  //   key: uuidv4(),
  //   label: 'themeSetting',
  //   path: ROUTE_PATHS.themeSettings,
  //   icon: PaletteIcon,
  //   subMenus: []
  // },
  {
    key: uuidv4(),
    label: 'bonusSetting',
    icon: CardGiftcardIcon,
    subMenus: [
      {
        key: uuidv4(),
        label: 'dailyBonus',
        path: ROUTE_PATHS.dailyBonus,
        icon: CircleOutlinedIcon,
        subMenus: []
      },
      {
        key: uuidv4(),
        label: 'weeklyBonus',
        path: ROUTE_PATHS.weeklyBonus,
        icon: CircleOutlinedIcon,
        subMenus: []
      },
      {
        key: uuidv4(),
        label: 'otherBonus',
        path: ROUTE_PATHS.otherBonus,
        icon: CircleOutlinedIcon
      },
      {
        key: uuidv4(),
        label: 'dailyCashback',
        path: ROUTE_PATHS.dailyCashback,
        icon: CircleOutlinedIcon,
        subMenus: []
      },
      {
        key: uuidv4(),
        label: 'weeklyCashback',
        path: ROUTE_PATHS.weeklyCashback,
        icon: CircleOutlinedIcon,
        subMenus: []
      }
      // {
      //   key: uuidv4(),
      //   label: 'depositBonus',
      //   path: ROUTE_PATHS.depositBonus,
      //   icon: CircleOutlinedIcon,
      //   subMenus: []
      // },
      // {
      //   key: uuidv4(),
      //   label: 'freeSpinBonus',
      //   path: ROUTE_PATHS.freeSpinBonus,
      //   icon: CircleOutlinedIcon
      // }
    ]
  },
  {
    key: uuidv4(),
    label: 'chatSettings',
    icon: ChatBubbleOutlineOutlinedIcon,
    subMenus: [
      {
        key: uuidv4(),
        label: 'chatRules',
        path: ROUTE_PATHS.chatRules,
        icon: CircleOutlinedIcon,
        subMenus: []
      },
      {
        key: uuidv4(),
        label: 'userChats',
        path: ROUTE_PATHS.userChats,
        icon: CircleOutlinedIcon,
        subMenus: []
      },
      {
        key: uuidv4(),
        label: 'offensiveWords',
        path: ROUTE_PATHS.words,
        icon: CircleOutlinedIcon,
        subMenus: []
      },
      {
        key: uuidv4(),
        label: 'languagesRoom',
        path: ROUTE_PATHS.chatLanguageRoom,
        icon: CircleOutlinedIcon,
        subMenus: []
      },
      {
        key: uuidv4(),
        label: 'chatGroups',
        path: ROUTE_PATHS.chatGroups,
        icon: CircleOutlinedIcon,
        subMenus: []
      },
      {
        key: uuidv4(),
        label: 'tipsTransaction',
        path: ROUTE_PATHS.tipsTransaction,
        icon: CircleOutlinedIcon,
        subMenus: []
      }
    ]
  },
  {
    key: uuidv4(),
    label: 'affiliates',
    icon: StreamIcon,
    subMenus: [
      {
        key: uuidv4(),
        label: 'affiliateStats',
        path: ROUTE_PATHS.affiliates,
        icon: CircleOutlinedIcon,
        subMenus: []
      },
      {
        key: uuidv4(),
        label: 'commissionSettlement',
        path: ROUTE_PATHS.settleAffiliateCommission,
        icon: CircleOutlinedIcon,
        subMenus: []
      },
      {
        key: uuidv4(),
        label: 'affiliateTransactions',
        path: ROUTE_PATHS.affiliateTransactions,
        icon: CircleOutlinedIcon,
        subMenus: []
      }
    ]
  },
  {
    key: uuidv4(),
    label: 'sponsors',
    path: ROUTE_PATHS.sponsors,
    icon: CampaignIcon,
    subMenus: []
  },
  {
    key: uuidv4(),
    label: 'referrals',
    icon: ShareIcon,
    subMenus: [
      {
        key: uuidv4(),
        label: 'referralStats',
        path: ROUTE_PATHS.referrals,
        icon: CircleOutlinedIcon,
        subMenus: []
      },
      {
        key: uuidv4(),
        label: 'referralBonus',
        path: ROUTE_PATHS.referralBonus,
        icon: CircleOutlinedIcon,
        subMenus: []
      }
    ]
  },
  {
    key: uuidv4(),
    label: 'cryptoInstruments',
    path: ROUTE_PATHS.cryptoInstruments,
    icon: LocalAtmIcon,
    subMenus: []
  },
  {
    key: uuidv4(),
    label: 'rankingLevels',
    path: ROUTE_PATHS.rankingLevels,
    icon: RewardIcon,
    subMenus: []
  },
  {
    key: uuidv4(),
    label: 'promotions',
    path: ROUTE_PATHS.promotions,
    icon: LocalOfferIcon,
    subMenus: []
  },
  {
    key: uuidv4(),
    label: 'reportAndFlag',
    path: ROUTE_PATHS.reportFlag,
    icon: FlagIcon,
    subMenus: []
  },
  {
    key: uuidv4(),
    label: 'announcements',
    path: ROUTE_PATHS.announcements,
    icon: AnnouncementSharpIcon,
    subMenus: []
  },
  {
    key: uuidv4(),
    label: 'crmTemplate',
    path: ROUTE_PATHS.crmTemplate,
    icon: EmailIcon,
    subMenus: []
  },
  {
    key: uuidv4(),
    label: 'cashierManagement',
    path: ROUTE_PATHS.paymentTransactions,
    icon: PaidOutlinedIcon,
    subMenus: []
  },
  {
    key: uuidv4(),
    label: 'withdrawRequests',
    path: ROUTE_PATHS.withdrawRequests,
    icon: FeedOutlinedIcon,
    subMenus: []
  },
  {
    key: uuidv4(),
    label: 'imageGallery',
    path: ROUTE_PATHS.assets,
    icon: CollectionsIcon,
    subMenus: []
  },
  {
    key: uuidv4(),
    label: 'cms',
    path: ROUTE_PATHS.cms,
    icon: LayersIcon,
    subMenus: []
  }
]
