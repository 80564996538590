import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants/index'
import axiosInstance, { microServices } from 'network/apis'

/**
 * Service to update promotion details
 */
export const updateAnnouncementDetailsService = (announcementData) => {
  return axiosInstance(METHOD_TYPES.put, '/announcement/update', announcementData, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: 'Announcements details updated successfully'
  })
}

/**
 * Service to create promotion
 * @param {object} data - object contains all required properties to create new promotion
 */
export const createAnnouncementService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/announcement/create', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: 'Announcement created successfully'
  })
}

/**
 * Service to fetch all announcement
 */
export const getAllAnnouncementsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/announcement/get', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}
