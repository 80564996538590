import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants/index'
import axiosInstance, { microServices } from 'network/apis'
import { SuccessMessage } from 'helpers/messages'

/**
 * Service to update admin user details
 */
export const updateAdminDetailsService = (userData) => {
  return axiosInstance(METHOD_TYPES.put, '/admins/update', userData, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.adminDetailsUpdated
  })
}

/**
 * Service to get admin user details
 */
export const getAdminDetailService = (adminId) => {
  return axiosInstance(METHOD_TYPES.get, '/admins/details', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: { adminId }
  })
}

/**
 * Service to update admin user status
 */
export const updateAdminStatusService = (adminUserData) => {
  return axiosInstance(METHOD_TYPES.put, '/admins/update-status', adminUserData, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    successMessage: 'Admin status updated successfully'
  })
}

/**
 * Service to create admin user
 * @param {object} data - object contains all required properties to create new admin
 */
export const createAdminService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/admins/create', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: 'Admin created successfully'
  })
}

/**
 * Service to fetch all admin users
 */
export const getAllAdminsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/admins/list', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

/**
 * Service to generate reset password link
 */
export const forgotPasswordService = (email) => {
  return axiosInstance(METHOD_TYPES.post, '/admins/forgot-password', { email }, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.resetPasswordLinkGenerated
  })
}

/**
 * Service to reset password
 */
export const resetPasswordService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/admins/reset-password', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.resetPasswordSuccessfully
  })
}

/**
 * Service to change password
 * @param {object} data - object contains old and new password
 */
export const updatePasswordService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/admins/change-password', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.changePassword
  })
}

/**
 * Service to generate QR
 */
export const generateQrCodeService = () => {
  return axiosInstance(METHOD_TYPES.post, '/admins/generate-secret-code', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.page
  })
}

/**
 * Service to verify 2fa authenticate token
 * @param {object} data - object contains token
 */
export const verifyTwoFactorTokenService = (token) => {
  return axiosInstance(METHOD_TYPES.post, '/admins/firstTime-2fa-authenticate-token', { token }, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.twoFactorEnabledSuccessfully
  })
}

/**
 * Service to deactivate 2fa authenticate token
 */
export const deactivateTwoFactorService = (adminId) => {
  return axiosInstance(METHOD_TYPES.post, '/admins/deactivate-2fa', { adminId }, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.twoFactorDisabledSuccessfully
  })
}

/**
 * Service to create admin user
 * @param {object} data - object contains all required properties to create new role
 */
export const createRoleService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/admins/create-role', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: 'Role created successfully'
  })
}

/**
 * Service to fetch all roles
 */
export const getAllRolesService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/admins/roles', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

/**
 * Service to update admin role
 */
export const updateAdminRoleService = (userData) => {
  return axiosInstance(METHOD_TYPES.put, '/admins/update-role', userData, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: SuccessMessage.adminDetailsUpdated
  })
}

/**
 * Service to fetch all permissions
 */
export const getAllPermissionsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/admins/permissions', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

/**
 * Service to get role details
 */
export const getRoleDetailService = (id) => {
  return axiosInstance(METHOD_TYPES.get, '/admins/role-details', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: { id }
  })
}
