import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants/index'
import axiosInstance, { microServices } from 'network/apis'

/**
 * Service to delete word details
 */
export const deleteWordsService = (id) => {
  return axiosInstance(METHOD_TYPES.delete, `/admins/remove-offensive-word/${id}`, {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: 'Word deleted successfully'
  })
}

/**
 * Service to create word
 * @param {object} data - object contains all required properties to create new word
 */
export const createWordsService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/admins/create-offensive-word', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: 'Word created successfully'
  })
}

/**
 * Service to fetch all words
 */
export const getAllWordsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/admins/get-offensive-word', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}
