import { lazy } from 'react'
import { ROUTE_PATHS } from 'utils/constants/index'
import Login from 'pages/Auth/Login'

export const routerList = [
  {
    path: ROUTE_PATHS.dashboard,
    component: lazy(() => import('pages/Dashboard')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.login,
    component: Login,
    hasAuth: false
  },
  {
    path: ROUTE_PATHS.forgotPassword,
    component: lazy(() => import('pages/Auth/ForgotPassword')),
    hasAuth: false
  },
  {
    path: ROUTE_PATHS.resetPassword,
    component: lazy(() => import('pages/Auth/ResetPassword')),
    hasAuth: false
  },
  {
    path: `${ROUTE_PATHS.settings}/*`,
    component: lazy(() => import('pages/Settings')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.profile,
    component: lazy(() => import('pages/Profile')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.changePassword,
    component: lazy(() => import('pages/Auth/ChangePassword')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.games,
    component: lazy(() => import('pages/GameManagement')),
    hasAuth: true
  },
  // {
  //   path: ROUTE_PATHS.gamesSportsbook,
  //   component: lazy(() => import('pages/GameManagement/components/Games')),
  //   hasAuth: true
  // },
  {
    path: ROUTE_PATHS.gamesProviders,
    component: lazy(() => import('pages/GameManagement/components/Providers')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.editProvider,
    component: lazy(() => import('pages/GameManagement/components/Providers/components/EditProvider')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.gamesAggregators,
    component: lazy(() => import('pages/GameManagement/components/Aggregators')),
    hasAuth: true
  },
  // {
  //   path: ROUTE_PATHS.sportsbettingTransactions,
  //   component: lazy(() => import('pages/TransactionsList/components/SportsbettingTransactions')),
  //   hasAuth: true
  // },
  // {
  //   path: ROUTE_PATHS.casinoProfitAndLossTransactions,
  //   component: lazy(() => import('pages/TransactionsList/components/ProfitAndLoss')),
  //   hasAuth: true
  // },
  // {
  //   path: ROUTE_PATHS.casinoTransactions,
  //   component: lazy(() => import('pages/TransactionsList/components/CasinoTransactions')),
  //   hasAuth: true
  // },
  {
    path: ROUTE_PATHS.cryptoBetTransactions,
    component: lazy(() => import('pages/TransactionsList/components/CryptoBetTransactions')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.rollerCoasterTransactions,
    component: lazy(() => import('pages/TransactionsList/components/RollerCoasterTransactions')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.gameSettings,
    component: lazy(() => import('pages/GameSettings')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.editGameSettings,
    component: lazy(() => import('pages/GameSettings/components/EditGameSetting')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.gameThemeSettings,
    component: lazy(() => import('pages/GameThemeSetting')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.createGameHeroTheme,
    component: lazy(() => import('pages/GameThemeSetting/components/CreateGameHeroTheme')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.editGameHeroTheme,
    component: lazy(() => import('pages/GameThemeSetting/components/EditGameHeroTheme')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.createGameBackgroundTheme,
    component: lazy(() => import('pages/GameThemeSetting/components/CreateGameBackgroundTheme')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.editGameBackgroundTheme,
    component: lazy(() => import('pages/GameThemeSetting/components/EditGameBackgroundTheme')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.upliftingWords,
    component: lazy(() => import('pages/UpliftingWords')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.admins,
    component: lazy(() => import('pages/AdminManagement')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.ipManagement,
    component: lazy(() => import('pages/AdminManagement/components/IpManagement')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.roleManagement,
    component: lazy(() => import('pages/AdminManagement/components/RoleManagement')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.siteConfiguration,
    component: lazy(() => import('pages/AdminManagement/components/SiteConfiguration')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.editSiteConfig,
    component: lazy(() => import('pages/AdminManagement/components/SiteConfiguration/components/EditSite')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.createRole,
    component: lazy(() => import('pages/AdminManagement/components/RoleManagement/components/CreateRole')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.editRole,
    component: lazy(() => import('pages/AdminManagement/components/RoleManagement/components/EditRole')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.rolesDetail,
    component: lazy(() => import('pages/AdminManagement/components/RoleManagement/components/RoleDetails')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.systemManagementLoginLogs,
    component: lazy(() => import('pages/AdminManagement/components/LoginLog')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.operationLog,
    component: lazy(() => import('pages/AdminManagement/components/OperationLog')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.createAdmin,
    component: lazy(() => import('pages/AdminManagement/components/CreateAdmin')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.adminsDetail,
    component: lazy(() => import('pages/AdminManagement/components/AdminDetails')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.editAdmin,
    component: lazy(() => import('pages/AdminManagement/components/EditAdmin')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.loginLog,
    component: lazy(() => import('pages/LoginLog')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.playerBalance,
    component: lazy(() => import('pages/PlayerManagement/components/PlayerBalance')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.onlinePlayer,
    component: lazy(() => import('pages/PlayerManagement/components/OnlinePlayers')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.users,
    component: lazy(() => import('pages/PlayerManagement')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.createUser,
    component: lazy(() => import('pages/PlayerManagement/components/CreatePlayer')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.editUser,
    component: lazy(() => import('pages/PlayerManagement/components/EditPlayer')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.usersDetail,
    component: lazy(() => import('pages/PlayerManagement/components/PlayerDetails')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.influencers,
    component: lazy(() => import('pages/InfluencerPlayerManagement')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.createInfluencer,
    component: lazy(() => import('pages/InfluencerPlayerManagement/components/CreateInfluencer')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.editInfluencer,
    component: lazy(() => import('pages/InfluencerPlayerManagement/components/EditInfluencer')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.influencerDetail,
    component: lazy(() => import('pages/InfluencerPlayerManagement/components/InfluencerDetails')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.themeSettings,
    component: lazy(() => import('pages/ThemeManagement')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.createThemeSetting,
    component: lazy(() => import('pages/ThemeManagement/components/CreateThemeSetting')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.editThemeSetting,
    component: lazy(() => import('pages/ThemeManagement/components/EditThemeSetting')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.affiliates,
    component: lazy(() => import('pages/AffiliateManagement')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.affiliateTransactions,
    component: lazy(() => import('pages/AffiliateManagement/components/AffiliateTransactions')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.settleAffiliateCommission,
    component: lazy(() => import('pages/AffiliateManagement/components/SettleAffiliateCommission')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.affiliatesPlayers,
    component: lazy(() => import('pages/AffiliateManagement/components/AffiliatePlayers')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.individualPlayersStats,
    component: lazy(() => import('pages/AffiliateManagement/components/AffiliatePlayerStats')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.editAffiliateSettings,
    component: lazy(() => import('pages/AffiliateManagement/components/EditAffiliateCommission')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.createAffiliateCommission,
    component: lazy(() => import('pages/AffiliateManagement/components/CreateAffiliateCommission')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.sponsors,
    component: lazy(() => import('pages/SponsorManagement')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.createSponsor,
    component: lazy(() => import('pages/SponsorManagement/components/CreateSponsor')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.editSponsor,
    component: lazy(() => import('pages/SponsorManagement/components/EditSponsor')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.reportFlag,
    component: lazy(() => import('pages/ReportAndFlagManagement')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.reportedUserDetail,
    component: lazy(() => import('pages/ReportAndFlagManagement/components/ReportedUserDetails')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.promotions,
    component: lazy(() => import('pages/PromotionManagement')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.createPromotion,
    component: lazy(() => import('pages/PromotionManagement/components/CreatePromotion')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.editPromotion,
    component: lazy(() => import('pages/PromotionManagement/components/EditPromotion')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.viewPromotion,
    component: lazy(() => import('pages/PromotionManagement/components/ViewPromotion')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.announcements,
    component: lazy(() => import('pages/Announcements')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.createAnnouncement,
    component: lazy(() => import('pages/Announcements/components/CreateAnnouncement')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.editAnnouncement,
    component: lazy(() => import('pages/Announcements/components/EditAnnouncement')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.crmTemplate,
    component: lazy(() => import('pages/CrmTemplateManagement')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.createCrmTemplate,
    component: lazy(() => import('pages/CrmTemplateManagement/components/CreateCrmTemplate')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.editCrmTemplate,
    component: lazy(() => import('pages/CrmTemplateManagement/components/EditCrmTemplate')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.transactions,
    component: lazy(() => import('pages/TransactionsList')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.paymentTransactions,
    component: lazy(() => import('pages/PaymentTransactions')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.withdrawRequests,
    component: lazy(() => import('pages/WithdrawRequests')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.assets,
    component: lazy(() => import('pages/AssetsManagement')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.cms,
    component: lazy(() => import('pages/CmsManagement')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.createCms,
    component: lazy(() => import('pages/CmsManagement/components/CreateCms')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.editCms,
    component: lazy(() => import('pages/CmsManagement/components/EditCms')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.createBanner,
    component: lazy(() => import('pages/BannerManagement/components/CreateBanner')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.editBanner,
    component: lazy(() => import('pages/BannerManagement/components/EditBanner')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.bonus,
    component: lazy(() => import('pages/BonusManagement')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.editJoiningBonus,
    component: lazy(() => import('pages/BonusManagement/components/EditJoiningBonus')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.editReferralBonus,
    component: lazy(() => import('pages/BonusManagement/components/EditReferralBonus')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.depositBonus,
    component: lazy(() => import('pages/BonusManagement/components/DepositBonus')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.dailyBonus,
    component: lazy(() => import('pages/BonusManagement/components/DailyBonus')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.weeklyBonus,
    component: lazy(() => import('pages/BonusManagement/components/WeeklyBonus')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.dailyCashback,
    component: lazy(() => import('pages/BonusManagement/components/DailyCashback')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.editWeeklyCashbackBonus,
    component: lazy(() => import('pages/BonusManagement/components/WeeklyCashback/components/Edit')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.editDailyCashbackBonus,
    component: lazy(() => import('pages/BonusManagement/components/DailyCashback/components/Edit')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.weeklyCashback,
    component: lazy(() => import('pages/BonusManagement/components/WeeklyCashback')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.otherBonus,
    component: lazy(() => import('pages/BonusManagement/components/OtherBonuses')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.freeSpinBonus,
    component: lazy(() => import('pages/BonusManagement/components/FreeSpinBonus')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.createDepositBonus,
    component: lazy(() => import('pages/BonusManagement/components/DepositBonus/components/Create')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.createOtherBonus,
    component: lazy(() => import('pages/BonusManagement/components/OtherBonuses/components/Create')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.createFreeSpinBonus,
    component: lazy(() => import('pages/BonusManagement/components/FreeSpinBonus/components/Create')),
    hasAuth: true
  },
  // {
  //   path: ROUTE_PATHS.depositBonusDetail,
  //   component: lazy(() => import('pages/BonusManagement/components/DepositBonus/components/Details')),
  //   hasAuth: true
  // },
  // {
  //   path: ROUTE_PATHS.freeSpinBonusDetail,
  //   component: lazy(() => import('pages/BonusManagement/components/FreeSpinBonus/components/Details')),
  //   hasAuth: true
  // },
  {
    path: ROUTE_PATHS.editDepositBonus,
    component: lazy(() => import('pages/BonusManagement/components/DepositBonus/components/Edit')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.editDailyRewardBonus,
    component: lazy(() => import('pages/BonusManagement/components/DailyBonus/components/Edit')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.editWeeklyRewardBonus,
    component: lazy(() => import('pages/BonusManagement/components/WeeklyBonus/components/Edit')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.editOtherBonus,
    component: lazy(() => import('pages/BonusManagement/components/OtherBonuses/components/Edit')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.editFreeSpinBonus,
    component: lazy(() => import('pages/BonusManagement/components/FreeSpinBonus/components/Edit')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.gamesCategories,
    component: lazy(() => import('pages/GameManagement/components/Categories')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.createCategory,
    component: lazy(() => import('pages/GameManagement/components/Categories/components/CreateCategory')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.editCategory,
    component: lazy(() => import('pages/GameManagement/components/Categories/components/EditCategory')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.databaseExplorer,
    component: lazy(() => import('pages/DatabaseExplorer')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.chatRules,
    component: lazy(() => import('pages/ChatManagement/ChatRules')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.userChats,
    component: lazy(() => import('pages/ChatManagement/components/UserChats')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.userChatDetails,
    component: lazy(() => import('pages/ChatManagement/components/UserChatDetails')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.chatLanguageRoom,
    component: lazy(() => import('pages/ChatManagement/components/ChatLanguageRoom')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.tipsTransaction,
    component: lazy(() => import('pages/ChatManagement/components/UserTipManagement')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.chatGroups,
    component: lazy(() => import('pages/ChatManagement/components/ChatGroupsManagement')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.createChatGroups,
    component: lazy(() => import('pages/ChatManagement/components/ChatGroupsManagement/components/CreateGroupsChat')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.editChatGroups,
    component: lazy(() => import('pages/ChatManagement/components/ChatGroupsManagement/components/EditGroupsChat')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.createChatLanguageRoom,
    component: lazy(() => import('pages/ChatManagement/components/ChatLanguageRoom/components/CreateLanguageRoom')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.words,
    component: lazy(() => import('pages/ChatManagement/components/OffensiveWords')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.createWord,
    component: lazy(() => import('pages/ChatManagement/components/OffensiveWords/components/CreateWords')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.referrals,
    component: lazy(() => import('pages/ReferralManagement')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.referralUsers,
    component: lazy(() => import('pages/ReferralManagement/components/ReferralUsers')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.individualReferralUsers,
    component: lazy(() => import('pages/ReferralManagement/components/IndividualReferredUserStats')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.referralBonus,
    component: lazy(() => import('pages/ReferralManagement/components/ReferralBonusSettings')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.editReferralBonusSettings,
    component: lazy(() => import('pages/ReferralManagement/components/ReferralBonusSettings/components/Edit')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.rankingLevels,
    component: lazy(() => import('pages/RankingManagement')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.createRankingLevel,
    component: lazy(() => import('pages/RankingManagement/components/CreateRankingLevel')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.editRankingLevel,
    component: lazy(() => import('pages/RankingManagement/components/EditRankingLevel')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.cryptoInstruments,
    component: lazy(() => import('pages/CryptoManagement')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.editCryptoInstruments,
    component: lazy(() => import('pages/CryptoManagement/components/Edit')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.cryptoFutureReport,
    component: lazy(() => import('pages/TransactionsList/components/CryptoFutureReport')),
    hasAuth: true
  },
  {
    path: ROUTE_PATHS.rollerCoasterReport,
    component: lazy(() => import('pages/TransactionsList/components/RollerCoasterReport')),
    hasAuth: true
  }
]
