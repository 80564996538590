import { useEffect } from 'react'
import { Toolbar } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useDispatch, useSelector } from 'react-redux'
import { handleDrawerHamValue } from 'redux-thunk/redux/hamSlice'
import Header from '../Header'
import Sidebar from '../Sidebar'
import { Main, StyledBox, StyledMainLayoutChildren } from './styles'

const MainLayout = ({ children }) => {
  const dispatch = useDispatch()
  const isSideNavOpen = useSelector(state => state.ham.openHam)
  const matches = useMediaQuery((theme) => theme.breakpoints.down('lg'))

  useEffect(() => {
    if (matches) {
      dispatch(handleDrawerHamValue(false))
    }
  }, [matches])

  return (
    <StyledBox display='flex'>
      <Header />
      <Sidebar />
      <StyledBox
        component={Main}
        open={isSideNavOpen}
        flexGrow={1}
        padding={3}
        height='100vh'
        overflow='auto'
        sx={isSideNavOpen && { maxWidth: 'calc(100% - 250px)' }}
      >
        <Toolbar />
        <StyledMainLayoutChildren>
          {children}
        </StyledMainLayoutChildren>
      </StyledBox>
    </StyledBox>
  )
}

export default MainLayout
