import { createSlice } from '@reduxjs/toolkit'
import {
  fetchAllTransactions,
  fetchAllWithdrawRequests,
  fetchAllPaymentTransactions
} from 'redux-thunk/thunk/transactions'

const initialState = {
  allTransactions: null,
  allWithdrawRequests: null,
  allPaymentTransactions: null
}

const transactionSlice = createSlice({
  name: 'transactionSlice',
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllTransactions.fulfilled, (state, action) => {
        return {
          ...state,
          allTransactions: action.payload
        }
      })
      .addCase(fetchAllWithdrawRequests.fulfilled, (state, action) => {
        return {
          ...state,
          allWithdrawRequests: action.payload
        }
      })
      .addCase(fetchAllPaymentTransactions.fulfilled, (state, action) => {
        return {
          ...state,
          allPaymentTransactions: action.payload
        }
      })
  }
})

export default transactionSlice.reducer
