import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  createThemeService,
  getAllThemesService,
  getThemeDetailService,
  updateThemeDetailsService,
  updateThemeStatusService,
  setDefaultThemeService,
  deleteThemeService
} from 'network/services/themes.service'

/**
 * Get All Themes Thunk
 */
export const fetchAllThemes = createAsyncThunk(
  'fetch/allThemes',
  async (data, thunkApi) => {
    try {
      const res = await getAllThemesService(data)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

export const fetchThemesDetails = createAsyncThunk(
  'fetch/themeDetail',
  async (themeId, thunkApi) => {
    try {
      const res = await getThemeDetailService(themeId)
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Create Theme Thunk
 */
export const createTheme = createAsyncThunk(
  'create/theme',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await createThemeService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Update Theme Thunk
 */
export const updateTheme = createAsyncThunk(
  'update/themeDetail',
  async ({ data, onSuccess }, thunkApi) => {
    try {
      const res = await updateThemeDetailsService(data)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Change Theme Status Thunk
 */
export const updateThemeStatus = createAsyncThunk(
  'update/themeStatus',
  async ({ data, onSuccess, handleDialogClose }, thunkApi) => {
    try {
      const res = await updateThemeStatusService(data)
      onSuccess()
      handleDialogClose()
      return res
    } catch (error) {
      handleDialogClose()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Delete Theme Thunk
 */
export const deleteTheme = createAsyncThunk(
  'delete/theme',
  async ({ themeId, onSuccess }, thunkApi) => {
    try {
      const res = await deleteThemeService(themeId)
      onSuccess()
      return res
    } catch (error) {
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)

/**
 * Reset Theme Thunk
 */
export const resetDefaultTheme = createAsyncThunk(
  'reset/set-default-theme',
  async ({ onFinish }, thunkApi) => {
    try {
      const res = await setDefaultThemeService()
      onFinish()
      return res
    } catch (error) {
      onFinish()
      return thunkApi.rejectWithValue(error[0].description)
    }
  }
)
