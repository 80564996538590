import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Divider } from '@mui/material'
import PropTypes from 'prop-types'
import { fetchAllConfigs } from 'redux-thunk/thunk/siteConfig'
import { DrawerHeader, StyledDrawer } from './styles'

const AppDrawer = ({ children, openDrawer, handleDrawer, text }) => {
  const dispatch = useDispatch()
  // const { siteConfigs } = useSelector((state) => state?.siteConfiguration)

  useEffect(() => {
    dispatch(
      fetchAllConfigs()
    )
  }, [])

  return (
    <StyledDrawer
      anchor='left'
      variant='persistent'
      open={openDrawer}

    >
      <Box>
        <DrawerHeader>
          <img
            style={{ width: '150px', margin: '0 auto', maxHeight: '162px' }}
            // src={siteConfigs?.getSiteSettings?.[0].logo}
            src={require('./wofe.png')}
            alt='logo'
          />
          {/* <CustomTypography
            sx={{ textAlign: 'center' }}
            variant='h6'
            value={text}
          /> */}
        </DrawerHeader>
      </Box>
      <Divider sx={{ backgroundImage: 'linear-gradient(90deg,transparent,rgba(0,0,0,.5),transparent)' }} light />
      <Box sx={{ overflow: 'auto' }}>
        {children}
      </Box>
    </StyledDrawer>
  )
}

export default AppDrawer

AppDrawer.defaultProps = {
  openDrawer: true,
  handleDrawer: () => { },
  text: 'Default'
}

AppDrawer.propTypes = {
  openDrawer: PropTypes.bool.isRequired,
  handleDrawer: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  text: PropTypes.string.isRequired
}
