import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants/index'
import axiosInstance, { microServices } from 'network/apis'

/**
 * Service to update theme details
 */
export const updateThemeDetailsService = (themeData) => {
  return axiosInstance(METHOD_TYPES.put, '/theme-setting/update', themeData, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: 'Theme updated successfully'
    // successMessage: SuccessMessage.themeDetailsUpdated
  })
}

/**
 * Service to get theme details
 */
export const getThemeDetailService = (themeId) => {
  return axiosInstance(METHOD_TYPES.get, '/theme-setting/details', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: { themeId }
  })
}

/**
 * Service to update theme status
 */
export const updateThemeStatusService = (themeUserData) => {
  return axiosInstance(METHOD_TYPES.put, '/theme-setting/update-status', themeUserData, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    successMessage: 'Theme status updated successfully'
  })
}

/**
 * Service to create theme
 * @param {object} data - object contains all required properties to create new theme
 */
export const createThemeService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/theme-setting/create', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: 'Theme created successfully'
  })
}

/**
 * Service to fetch all themes
 */
export const getAllThemesService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/theme-setting/list', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

/**
 * Service to delete theme
 */
export const deleteThemeService = (id) => {
  return axiosInstance(METHOD_TYPES.delete, `/theme-setting/${id}/delete`, {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table
  })
}

/**
 * Service to set default theme
 */
export const setDefaultThemeService = () => {
  return axiosInstance(METHOD_TYPES.post, '/theme-setting/default-theme', {}, {
    server: microServices.USER_URL,
    loader: null,
    successMessage: 'Reset default theme successfully'
  })
}
