export const SuccessMessage = {
  login: 'loggedInSuccess',
  logout: 'loggedOutSuccess',
  changePassword: 'passwordUpdateSuccess',
  playerStatusUpdate: 'updatePlayerStatusSuccess',
  createOperator: 'operatorUserCreatedSuccess',
  resetPassword: 'resetPasswordSuccess',
  resetPasswordSuccessfully: 'resetPasswordSuccessfully',
  resetPasswordLinkGenerated: 'resetPasswordLinkGeneratedSuccessfully',
  gameSettingUpdated: 'updateGameSettingSuccess',
  adminDetailsUpdated: 'adminDetailsUpdated',
  twoFactorEnabledSuccessfully: 'twoFactorEnabledSuccessfully',
  twoFactorDisabledSuccessfully: 'twoFactorDisabledSuccessfully',
  gameStatusUpdatedSuccessfully: 'gameStatusUpdatedSuccessfully',
  providerStatusUpdatedSuccessfully: 'providerStatusUpdatedSuccessfully',
  imageUploadedSuccessfully: 'imageUploadedSuccessfully',
  statusUpdatedSuccessfully: 'Status updated successfully',
  categoryCreatedSuccessfully: 'categoryCreatedSuccessfully',
  categoryUpdatedSuccessfully: 'categoryUpdatedSuccessfully',
  providersDataUpdateSuccessfully: 'providersDataUpdateSuccessfully',
  chatRulesUpdatedSuccessfully: 'chatRulesUpdatedSuccessfully'
}

export const ErrorMessage = {
  internalServerError: 'internalServerError',
  unAuthorized: 'unAuthorized'
}

export const errorCodeToMessage = new Map([
  [600, 'internalServerError'],
  [601, 'loginFailedError'],
  [602, 'invalidCredentialsError'],
  [603, 'invalidTokenError'],
  [604, 'invalidSessionError'],
  [605, 'invalidAccessError'],
  [606, 'nonOperationalError'],
  [2001, 'createUserError'],
  [2004, 'dataMismatchError'],
  [2005, 'userNotFoundError'],
  [2006, 'duplicateEmailError']
])
