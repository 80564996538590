import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants/index'
import axiosInstance, { microServices } from 'network/apis'

export const getAllPlayersService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/users/list', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getPlayerDetailService = (userId) => {
  return axiosInstance(METHOD_TYPES.get, '/users/details', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: { userId }
  })
}

export const getPlayerCommentDetailService = (userId) => {
  return axiosInstance(METHOD_TYPES.get, '/users/comments', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: { userId }
  })
}

export const addPlayerGroupDetailsService = (data) => {
  return axiosInstance(METHOD_TYPES.put, '/users/add-user-group', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: 'Group added successfully'
  })
}

export const addPlayerCommentDetailsService = (data) => {
  return axiosInstance(METHOD_TYPES.put, '/users/add-comments', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: 'Comments added successfully'
  })
}

export const updatePlayerCommentStatusService = (data) => {
  return axiosInstance(METHOD_TYPES.put, '/users/update-comment', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: 'Comments status successfully'
  })
}

export const createPlayerService = (userData) => {
  return axiosInstance(METHOD_TYPES.post, '/users/create', userData, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: 'Player created successfully'
  })
}

export const updatePlayerService = (userData) => {
  return axiosInstance(METHOD_TYPES.put, '/users/update', userData, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: 'Player updated successfully'
  })
}

export const updatePlayerStatusService = (userData) => {
  return axiosInstance(METHOD_TYPES.put, '/users/update-status', userData, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    successMessage: 'Player status updated successfully'
  })
}

export const removePlayerMFAService = (userId) => {
  return axiosInstance(METHOD_TYPES.post, '/users/deactivate-2fa', { userId }, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    successMessage: 'Player MFA removed successfully'
  })
}

export const getLoginLogs = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/users/login-log', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const getOnlinePlayers = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/users/online-users', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

export const sendEmailToPlayersService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/users/send-custom-email', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: 'Email sent successfully'
  })
}

export const getEmailsDetailService = (userId) => {
  return axiosInstance(METHOD_TYPES.get, '/users/get-user-email', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: { userId }
  })
}

export const sendBonusAmountToPlayersService = (data) => {
  return axiosInstance(METHOD_TYPES.post, '/users/create-user-bonus', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    successMessage: 'Bonus sent successfully'
  })
}

export const getIndividualPlayerBonusDetailService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/users/get-user-bonus', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}
