import { Drawer, styled } from '@mui/material'
// import { colors } from 'theme/colors/index'

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'grid',
  alignItems: 'center',
  // background: colors.drawerBg,
  ...theme.mixins.toolbar
}))

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: theme.drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: theme.drawerWidth,
    boxSizing: 'border-box',
    borderRight: '0',
    '.sidebar-icon': {
      background: theme.colors.mainBg,
      color: theme.colors.darkGrey,
      borderRadius: '10px',
      boxShadow: `0 .3125rem .625rem 0 ${theme.colors.sidebarIconShadow}`,
      padding: '8px',
      minWidth: '0',
      marginRight: '15px'
    },
    '.MuiSvgIcon-root': {
      fill: theme.colors.textWhite,
      width: '1.125rem',
      height: '1.125rem'
    }
  }
}))
