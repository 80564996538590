import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants/index'
import axiosInstance, { microServices } from 'network/apis'

/**
 * Service to fetch all crypto-instruments
 */
export const getAllCryptoInstrumentsService = (data) => {
  return axiosInstance(METHOD_TYPES.get, '/crypto/crypto-instrument', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    params: data
  })
}

/**
 * Service to update crypto-instruments
 */
export const updateCryptoInstrumentsService = (data) => {
  return axiosInstance(METHOD_TYPES.put, '/crypto/update-crypto-instrument', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    headers: { 'Content-Type': 'multipart/form-data', Accept: '*/*' },
    successMessage: 'Crypto Instrument updated successfully'
  })
}

/**
 * Service to update cryptoInstruments status
 */
export const updateCryptoInstrumentsStatusService = (data) => {
  return axiosInstance(METHOD_TYPES.put, '/crypto/update-status', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table,
    successMessage: 'Crypto Instrument status updated successfully'
  })
}
