import { LOADER_HANDLER_TYPES, METHOD_TYPES } from 'utils/constants'
import axiosInstance, { microServices } from 'network/apis'

/**
 * Service to get all uploaded assets
 */
export const getAllAssetsService = () => {
  return axiosInstance(METHOD_TYPES.get, 'assets/all-assets', {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table
  })
}

/**
 * Service to upload new asset
 * @param {object} data - object contains all required properties to create new asset
 */
export const createAssetService = (data) => {
  return axiosInstance(METHOD_TYPES.post, 'assets/create-asset', data, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.submit,
    headers: { 'Content-Type': 'multipart/form-data', Accept: '*/*' },
    successMessage: 'Image uploaded successfully'
  })
}

/**
 * Service to delete asset
 */
export const deleteAssetService = (assetId) => {
  return axiosInstance(METHOD_TYPES.delete, `/assets/${assetId}/delete-asset`, {}, {
    server: microServices.USER_URL,
    loader: LOADER_HANDLER_TYPES.table
  })
}
