import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
import { Collapse, List } from '@mui/material'
import CustomListItem from '../CustomListItem/index'

const CustomList = ({ t, location, list, handleDrawerOptions, expandedMenuItem }) => {
  return (
    <List>
      {list.map((item) => {
        const NavIcon = item.icon
        return (
          <Fragment key={item.key}>
            <CustomListItem
              id={`sidebar_${item.label}`}
              {...(
                item.subMenus.length
                  ? {}
                  : { to: item.path, component: RouterLink }
              )}
              onClick={() => handleDrawerOptions(item.label)}
              selected={item.path === location.pathname}
              sx={{
                color: (theme) => item.path === location.pathname ? theme.colors.white : theme.colors.grey500,
                fontWeight: item.path === location.pathname ? '800' : '400',
                '& .sidebar-icon': {
                  background: (theme) =>
                    item.path === location.pathname
                      ? `${theme.colors.navyBlue} !important`
                      : 'transparent !important',
                  '& .MuiSvgIcon-root': {
                    fill: (theme) =>
                      item.path === location.pathname
                        ? `${theme.colors.white} !important`
                        : ''
                  }
                },
                '&:hover .sidebar-icon': {
                  background: (theme) => `${theme.colors.navyBlue}!important`,
                  '& .MuiSvgIcon-root': {
                    fill: (theme) => `${theme.colors.white}!important`
                  }
                }
              }}
              icon={<NavIcon />}
              text={t(item.label)}
              expandless={
                item.subMenus.length > 0 &&
                expandedMenuItem.includes(item.label)
              }
              // iconStyle={{
              //   minWidth: '0',
              //   marginRight: '15px'
              // }}
              hasSubMenu={Boolean(item?.subMenus.length)}
            />
            {item.subMenus.length
              ? (
                  item.subMenus.map((subItem) => {
                    return (
                      <Collapse
                        in={expandedMenuItem.includes(item.label)}
                        timeout='auto'
                        unmountOnExit
                        key={subItem.key}
                      >
                        <List component='div' disablePadding>
                          <CustomListItem
                            sx={{
                              color: (theme) => item.path === location.pathname ? theme.colors.white : theme.colors.grey500,
                              pl: 4,
                              '& .sidebar-icon': {
                                background: (theme) =>
                                  location.pathname.includes(subItem.path)
                                    ? `${theme.colors.navyBlue} !important`
                                    : 'transparent !important',
                                '& .MuiSvgIcon-root': {
                                  fill: (theme) =>
                                    location.pathname.includes(subItem.path)
                                      ? `${theme.colors.white} !important`
                                      : ''
                                }
                              },
                              '&:hover .sidebar-icon': {
                                background: (theme) => `${theme.colors.navyBlue}!important`,
                                '& .MuiSvgIcon-root': {
                                  fill: (theme) => `${theme.colors.white}!important`
                                }
                              }
                            }}
                            isbutton
                            id={`sidebar_${subItem.label}`}
                            to={subItem.path}
                            component={RouterLink}
                            text={t(subItem.label)}
                            icon={subItem.icon ? <subItem.icon /> : <></>}
                            hasSubMenu={false}
                            iconStyle={{
                              '& .MuiSvgIcon-root': {
                                fill: (theme) => theme.colors.white,
                                width: '20px'
                              }
                            }}
                          />
                        </List>
                      </Collapse>
                    )
                  })
                )
              : (
                <></>
                )}
          </Fragment>
        )
      })}
    </List>
  )
}

CustomList.defaultProps = {
  list: [],
  handleDrawerOptions: () => { },
  expandedMenuItem: []
}

CustomList.propTypes = {
  t: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  list: PropTypes.array.isRequired,
  handleDrawerOptions: PropTypes.func.isRequired,
  expandedMenuItem: PropTypes.array.isRequired
}
export default CustomList
